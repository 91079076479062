<template>
  <img :src="$route.params.imagePath" />
</template>

<script>
export default {
  props: ['imagePath']
}
</script>

<style scoped>
IMG {
  width: 100%;
}
</style>
